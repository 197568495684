
var cookieGdpr = {
    init: function () {
        $('#gtcookies-overlay, #gtcookies-modal-container').appendTo(document.body);

        //Check if user already saved cookies
        if (typeof C_P_DISPLAY_MODAL !== 'undefined'
            && C_P_DISPLAY_MODAL == 1
            && typeof C_P_COOKIE_VALUE !== 'undefined'
            && Object.keys(C_P_COOKIE_VALUE).length === 0
            && C_P_COOKIE_VALUE.constructor === Object) {
            this.displayModal();
        }
    },
    displayModal: function () {
        $('body, html').animate({ 'scrollTop': 0 }, 0).addClass('open-gtcookies');

        if (typeof $.uniform !== "undefined") {
            $.uniform.restore(".gtcookies-finality-checkbox");
        }

        if (C_P_FINALITIES_COUNT === 0) {
            return;
        }

        if (C_P_NOT_AVAILABLE === 0) {
            return;
        } else {
            this.displayOverlay();
            $('#gtcookies-modal').addClass('open');
            $('#gtcookies-modal .gtcookies-close').addClass('hide');
            $('.gtcookies-reject').addClass('hide');
            $('.gtcookies-accept-all-label').addClass('hide');
        }

        this.checkEvenDimensions('#gtcookies-modal');
        $(window).resize(function () {
            cookieGdpr.checkEvenDimensions('#gtcookies-modal');
        });
    },
    displayModalAdvanced: function (modal) {
        $('body, html').animate({ 'scrollTop': 0 }, 0);

        if (typeof $.uniform !== "undefined") {
            $.uniform.restore(".gtcookies-finality-checkbox");
        }

        if (C_P_FINALITIES_COUNT === 0) {
            return;
        }

        this.displayOverlay();

        if (C_P_NOT_AVAILABLE === 0) {
            $('#gtcookies-modal-not-available').show();
            $('#gtcookies-overlay').click(function () {
                cookieGdpr.close();
            });
        } else {
            $('#gtcookies-modal').addClass('open');
            $('.gtcookies-finalities').addClass('open');
            this.displaySavePreferencesButton();
            if (modal) {
                $('#gtcookies-modal .gtcookies-close').addClass('hide');
            }
        }

        this.checkEvenDimensions('#gtcookies-modal');
    },
    save: function () {
        return cookieGdpr.sendForm();
    },
    acceptAllCookies: function () {
        $('input:radio:not(:disabled)[name^=gtcookies-finality-][value=on]').prop('checked', true);

        return cookieGdpr.sendForm();
    },
    rejectAllCookies: function () {
        $('input:radio:not(:disabled)[name^=gtcookies-finality-][value=off]').prop('checked', true);

        return cookieGdpr.sendForm();
    },
    displayOverlay: function () {
        if (C_P_OVERLAY === '1') {
            $('#gtcookies-overlay').show();
        }
    },
    close: function () {
        $('html, body').removeClass('open-gtcookies');
        $('#gtcookies-modal, #gtcookies-modal-not-available, #gtcookies-overlay, .gtcookies-finalities, .gtcookies-save').removeClass('open');
    },
    displaySavePreferencesButton: function () {
        $('.gtcookies-save').removeClass('hide');
        $('.gtcookies-reject').removeClass('hide');
        $('.gtcookies-accept-all-label').removeClass('hide');
        $('.gtcookies-accept-label').addClass('hide');
    },
    sendForm: function () {
        if (!C_P_REFRESH) {
            $.ajax({
                type: "POST",
                data: 'savegtcookiesPreferences=&' + $('#gtcookies-form').serialize(),
            });

            cookieGdpr.close();

            return false;
        } else {
            return true;
        }
    }
};

$(document).ready(() => {
    var btnMoreInformation = '.gtcookies-more-information';
    var gtcookiesModal = '#gtcookies-modal';
    var gtcookiesFinalities = '.gtcookies-finalities';
    var gtcookieTab = '.gtcookies-nav-tabs a';
    var gtcookieTabPane = '.gtcookies-tab-pane';

    $('body').on('click', btnMoreInformation, function (e) {
        $(gtcookiesFinalities).toggleClass('open');
        e.preventDefault();
    });

    $('body').on('click', gtcookieTab, function (e) {
        var target = $(this).attr('href');
        $(gtcookieTab).removeClass('active');
        $(gtcookieTabPane).removeClass('active');
        $(this).addClass('active');
        $(target).addClass('active');
        e.preventDefault();
    });

    cookieGdpr.init();

    $('body').on('click', '.gtcookies-display-modal', function () {
        return cookieGdpr.displayModal();
    });
    $('body').on('click', '.gtcookies-display-modal-advanced', function () {
        cookieGdpr.displayModalAdvanced(false); return false;
    });
    $('body').on('click', '.gtcookies-reject', function () {
        return cookieGdpr.rejectAllCookies();
    });
    $('body').on('click', '.gtcookies-save', function () {
        return cookieGdpr.save();
    });
    $('body').on('click', '.gtcookies-more-information', function () {
        cookieGdpr.displayModalAdvanced(); return false;
    });
    $('body').on('click', '.gtcookies-accept', function () {
        return cookieGdpr.acceptAllCookies();
    });
});